<template>
  <div>
    <div class="card mb-3" style="max-height: 50vh; overflow-x: hidden; overflow-y: scroll;">
      <ChatMessage class="m-3" v-for="(message, index) in chat.messages" :key="'-' + index" :message="message" />
    </div>
    <div class="mb-3">
      <textarea :disabled="newMessageIsSending" class="form-control" rows="5" placeholder="Votre message" v-model="newMessage"></textarea>
    </div>
    <div class="text-end">
      <button :disabled="newMessageIsSending" class="btn btn-primary" @click="sendNewMessage">
        Envoyer
        <div v-if="newMessageIsSending" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
      </button>
    </div>
  </div>
</template>

<script>
import ChatMessage from "@/components/ChatMessage";
export default {
  name: "Chat",
  components: { ChatMessage },
  emits: ['message:new'],
  data: () => ({
    newMessage: '',
    newMessageIsSending: false,
    siteUrl: window.location.protocol + '//' + window.location.host
  }),
  props: {
    chat: {
      required: false,
      default: () => ({
        id: null,
        messages: [],
      })
    }
  },
  methods: {
    sendNewMessage() {
      this.newMessageIsSending = true
      setTimeout(() => {
        this.$emit('message:new', this.newMessage, this.newMessageResult, this.siteUrl)
      }, 150 /* slow down process a little => more user-friendly */)
    },
    newMessageResult(isSuccess) {
      if (isSuccess) {
        this.newMessage = ''
      }
      this.newMessageIsSending = false
    },
  }
}
</script>

<style scoped></style>
