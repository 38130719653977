<template>
    <div>
        <SimpleAccordion>
            <template v-slot:accordionTitle>
                <span class="me-3">Statuts</span>
                <button class="btn btn-primary btn-sm" @click.prevent="openModalStatusAdd">
                    <font-awesome-icon icon="fa-solid fa-plus"/>
                </button>
            </template>
            <template v-slot:accordionContent>
                <StatusElement
                    v-for="(sta, index) in status" :key="'status-' + index"
                    :is-status-present-in-current-user-status-list="isStatusPresentInCurrentUserStatusList(sta)"
                    :sta="sta"
                    :remove-method="() => removeStatus(sta)"
                />
            </template>
        </SimpleAccordion>
        <BlankModal ref="statusModal" :modal-id="statusModalId">
            <template v-slot:modalHeader>Ajouter un statut</template>
            <template v-slot:modalBody>
                <vue-good-table
                    :is-loading="statusListIsUpdating"
                    :columns="columns"
                    :rows="statusList"
                    :search-options="{
            enabled: true,
          }"
                    :pagination-options="pagination"
                    styleClass="vgt-table striped bordered condensed"
                >
                    <template v-slot:table-row="props">
                        <div v-if="props.column.field === 'actions'" class="text-end">
                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                @click="addStatus(props.row)"
                            >
                                Ajouter
                            </button>
                        </div>
                        <div v-else-if="props.column.field === 'isPublic'">
                            {{ props.formattedRow[props.column.field] ? 'Oui' : 'Non' }}
                        </div>
                        <div v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </div>
                    </template>
                </vue-good-table>
            </template>
        </BlankModal>
    </div>
</template>

<script>
import backendApi from "@/backend/api"
import BlankModal from "@/components/BlankModal";
import SimpleAccordion from "@/components/SimpleAccordion";
import random from "@/helpers/random";
import {mapActions, mapState} from "vuex";
import store from "@/store";
import StatusElement from "@/components/StatusElement.vue";

export default {
    name: "StatusAccordion",
    components: {StatusElement, SimpleAccordion, BlankModal},
    props: {
        status: {
            required: false,
            default: () => [],
        },
        addStatusMethod: {
            type: Function,
            required: false,
            default: () => Promise.resolve(),
        },
        removeStatusMethod: {
            type: Function,
            required: false,
            default: () => Promise.resolve(),
        },
    },
    data: () => ({
        statusModalId: random.htmlId(),
        columns: [
            {
                label: 'Valeur',
                field: 'value',
            },
            {
                label: 'Public',
                field: 'isPublic',
            },
            {
                label: 'Actions',
                field: 'actions',
                width: '1%',
            }
        ],
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'top',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
            // infoFn: (params) => `Page ${params.currentPage}`,
        },
        currentUserStatus: []
    }),
    computed: {
        ...mapState('status', {statusList: 'list', statusListIsUpdating: 'listIsUpdating'}),
    },
    methods: {
        ...mapActions('status', ['updateStatusList']),
        ...mapActions('notifications', ['addNotification']),
        openModalStatusAdd() {
            this.updateStatusList()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.statusModal.$el).show()
        },
        addStatus(row) {
            this.addStatusMethod(row.id)
                .then(() => {
                    this.addNotification({status: 'success', message: 'Statut ajouté'})
                })
                .catch(backendApi.notificationOnError)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.statusModal.$el).hide()
        },
        removeStatus(status) {
            this.removeStatusMethod(status.id)
                .then(() => {
                    this.addNotification({status: 'success', message: 'Statut retiré'})
                })
                .catch(backendApi.notificationOnError)
        },
        async getCurrentUserStatus() {
            try {
                this.currentUserStatus = await backendApi.getStatus(store.state.login.user.token)
            } catch (error) {
                console.error("Une erreur s'est produite : ", error);
                throw error;
            }
        },
        isStatusPresentInCurrentUserStatusList(sta) {
            return this.currentUserStatus.some(item => item.id === sta.id && item.isPublic === sta.isPublic)
        },
        defineStatusClass(sta) {
            return {
                'public-status-for-current-user': sta.isPublic && this.isStatusPresentInCurrentUserStatusList(sta),
                'private-status-for-current-user': !sta.isPublic && this.isStatusPresentInCurrentUserStatusList(sta),
                'public-status-for-another-user': sta.isPublic && !this.isStatusPresentInCurrentUserStatusList(sta),
            }
        }
    },
    mounted() {
        this.getCurrentUserStatus()
    }
}
</script>

<style scoped>
.public-status-for-current-user {
    background-color: #2cb4db !important;
    color: rgb(33, 37, 41) !important;
}

.private-status-for-current-user {
    background-color: #172B4D !important;
    color: white !important;
}

.public-status-for-another-user {
    background-color: #41b883 !important;
    color: rgb(33, 37, 41) !important;
}
</style>
