<template>
  <div class="card">
    <h5 class="card-title p-1 small">
      De {{ message.fromUser.username }} -
      {{ message.fromUser.userInfos.firstName }} {{ message.fromUser.userInfos.lastName }}
      {{ getFirstUserEmail(message) }}
    </h5>
    <h6 class="card-subtitle mb-2 text-muted p-1 small">Le {{ message.createdAt | humanReadableDate }}</h6>
    <hr class="mt-0 mb-1">
    <p class="card-text p-1 small" style="white-space: pre-line">{{ message.value }}</p>
  </div>
</template>

<script>
export default {
  name: "ChatMessage",
  props: {
    message: {
      required: false,
      default: () => ({

      })
    }
  },
  methods: {
    getFirstUserEmail(message) {
      let email = message.fromUser.userInfos.emails[0]?.value
      if (email) {
        return '- Email : ' + email
      }

      return ''
    }
  }
}
</script>

<style scoped>

</style>
