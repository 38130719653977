import backendApi from '../../backend/api'

export default {
  namespaced: true,
  state: () => ({
    dashboardData: {
      updateInProgress: false,
      data: {
        averageResponseTime: [],
        missionOrderMap: [],
        quotationNumber: {
          validated: 0,
          pending: 0,
          refused: 0,
        },
        ticketMap: [],
        ticketNumber: {
          closed: 0,
          open: 0,
        },
        ticketNumberPerCity: [],
      },
    },
    dashboardConfig: {
      fetchingDataInProgress: false,
      updateInProgress: false,
      data: {
        averageResponseTime: false,
        missionOrderMap: false,
        quotationNumber: false,
        ticketMap: false,
        ticketNumber: false,
        ticketNumberPerCity: false,
      },
    },
  }),
  mutations: {
    UPDATE_DASHBOARD_DATA(store, payload) {
      for (const property in payload) {
        store.dashboardData.data[property] = payload[property]
      }
    },
    UPDATE_DASHBOARD_CONFIG(store, payload) {
        store.dashboardConfig.data = payload
    }
  },
  actions: {
    async getDashboardData(context) {
      context.state.dashboardData.updateInProgress = true
      return await backendApi
        .getDashboardData(context.rootState.login.user.token)
        .then((data) => {
          context.commit('UPDATE_DASHBOARD_DATA', data)
        })
        .catch(() => {
        })
        .finally(() => {
          context.state.dashboardData.updateInProgress = false
        })
    },
    async getDashboardConfigData(context) {
      context.state.dashboardConfig.fetchingDataInProgress = true
      return await backendApi
        .getDashboardConfigData(context.rootState.login.user.token)
        .then((data) => {
          context.commit('UPDATE_DASHBOARD_CONFIG', data)
        })
        .catch(() => {
        })
        .finally(() => {
          context.state.dashboardConfig.fetchingDataInProgress = false
        })
    },
    async updateDashboardConfigData(context, payload) {
      context.state.dashboardConfig.updateInProgress = true
      return await backendApi
          .updateDashboardConfigData(context.rootState.login.user.token, payload)
          .then((data) => {
            context.commit('UPDATE_DASHBOARD_CONFIG', data)
            context.dispatch('notifications/addNotification', { status: 'success', message: 'Tableau de bord configuré avec succès' }, {root: true})
          })
          .catch(() => {
          })
          .finally(() => {
            context.state.dashboardConfig.updateInProgress = false
          })
    },
  },
  getters: {
    dashboardData: (state) => state.dashboardData,
    dashboardConfig: (state) => state.dashboardConfig,
  },
}
