<template>
  <div class="accordion" id="missionOrdersAccordion">
    <div class="accordion-item">
      <h2 class="accordion-header" id="missionOrderListTitle">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#missionOrderList" aria-expanded="false">
          Ordres de mission
        </button>
      </h2>
      <div id="missionOrderList" class="accordion-collapse collapse" aria-labelledby="missionOrderListTitle" data-bs-parent="#missionOrdersAccordion">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Id</th>
            <th>Créé le</th>
            <th>Cloturé</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="missionOrder in missionOrders" :key="'order-' + missionOrder.id">
            <td>{{ missionOrder.id }}</td>
            <td>{{ missionOrder.createdAt | humanReadableDate }}</td>
            <td v-if="missionOrder.closedAt">{{ missionOrder.closedAt | humanReadableDate }}</td>
            <td v-else></td>
            <td>
              <button class="btn btn-primary"
                      @click="$router.push({
                        name: 'mission_order_view',
                        params: {missionOrderId: missionOrder.id}
                      })"
              >
                <font-awesome-icon icon="fa-regular fa-eye" style="font-size: 1rem"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionOrdersList",
  props: {
    missionOrders: {
      required: false,
      default: () => [],
    }
  },
}
</script>

<style scoped>
</style>
