import backendApi from '../../backend/api'

export default {
  namespaced: true,
  state: () => ({
    email: null,
    verificationCode: null,
    step: 1,
    isResetPassword: false,
    resetInProgress: false,
    error: null,
    success: null
  }),
  mutations: {
    RESET_STATE(store) {
      store.email = null;
      store.step = 1;
      store.isResetPassword = false;
      store.resetInProgress = false;
      store.verificationCode = false;
      store.success = null;
      store.error = null;
    },

    PREVIOUS_STEP(store) {
      store.step = store.step == 1 ? 1 : store.step - 1
    },

    NEXT_STEP(store) {
      store.step = store.step == 3 ? 3 : store.step + 1
    }
  },
  actions: {
    toggleResetPassword(context) {
      if (!context.state.isResetPassword) {
        context.state.isResetPassword = !context.state.isResetPassword
      } else {
        context.commit('RESET_STATE')
      }
    },
    askResetPassword(context, payload) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!payload.email) {
        context.state.error = "Adresse email réquis"
      } else if (
        payload.email
        && !payload.email.match(validRegex)
      ) {
        context.state.error = "Adresse email format incorrect"
      } else {
        context.state.error = null;
        context.state.resetInProgress = true
        const siteUrl = window.location.protocol + '//' + window.location.host
        backendApi.askResetPassword(payload, siteUrl)
          .then((res) => {
            context.state.success = res.message
            context.state.email = payload.email
            context.commit('NEXT_STEP')
          })
          .catch((error) => {
            context.state.error = error.message
          })
          .finally(() => {
            context.state.resetInProgress = false
          })
      }
    },

    resendVerificationCode(context) {
      context.state.resetInProgress = true;
      context.state.success = null;
      const payload = { email: context.state.email }
      const siteUrl = window.location.protocol + '//' + window.location.host

      backendApi.askResetPassword(payload, siteUrl)
        .then((res) => {
          context.state.success = res.message
        })
        .catch((error) => {
          context.state.error = error.message
        })
        .finally(() => {
          context.state.resetInProgress = false
        })
    },

    verificationConfirmationCode(context, payload) {
      if (!payload.verificationCode) {
        context.state.error = "Code de vérification réquis"
      } else {
        context.state.error = null;
        context.state.resetInProgress = true;
        backendApi.verificationCode(payload)
          .then(() => {
            context.state.verificationCode = payload.verificationCode
            context.commit('NEXT_STEP')
          })
          .catch((error) => {
            context.state.success = null;
            context.state.error = error.message
          })
          .finally(() => {
            context.state.resetInProgress = false
          })
      }
    },

    validateResetPassword(context, payload) {
      if (payload.password != payload.confirmPassword) {
        context.state.error = "Confirmation mot de passe incorrect"
      } else if (!payload.password) {
        context.state.error = "Mot de passe réquis"
      } else {
        context.state.resetInProgress = true
        context.state.error = null;
        const tmpPayload = {
          ...payload,
          verificationCode: context.state.verificationCode,
          email: context.state.email
        }
        backendApi.validateResetPassword(tmpPayload)
          .then((res) => {
            context.commit('RESET_STATE')
            context.dispatch('notifications/addNotification', {
              status: 'success',
              message: res.message
            }, { root: true })
          })
          .catch((error) => {
            context.state.error = error.message
          })
          .finally(() => {
            context.state.resetInProgress = false
          })
      }
    },

    backStep(context) {
      context.commit('PREVIOUS_STEP')
    },

    nextStep(context) {
      context.commit('NEXT_STEP')
    }
  },
  getters: {
  }
}
