<template>
  <div>
    <SimpleAccordion>
      <template v-slot:accordionTitle>
        <span class="me-3">Devis</span>
        <button v-if="$store.state.login.user.userData.accountType === 'workerAccount'" class="btn btn-primary btn-sm " @click.prevent="openModalQuotationAdd">
          <font-awesome-icon icon="fa-solid fa-plus"/>
        </button>
      </template>
      <template v-slot:accordionContent>
        <vue-good-table styleClass="vgt-table striped bordered condensed" :columns="columns" :rows="quotations">
          <template v-slot:table-row="props">
            <div v-if="props.column.field === 'actions'" class="d-flex flex-column gap-2">
              <button class="btn btn-primary" @click="downloadDocument(props.row.document.randomIdentifier, props.row.document.originalName)">
                <font-awesome-icon icon="fa-solid fa-file-arrow-down" style="font-size: 1rem"/>
              </button>
              <div class="btn-group" v-if="!props.row.acceptedAt && props.row.document.ownedBy.username === $store.state.login.user.userData.username">
                <button type="button" class="btn btn-warning dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Désactiver le devis
                </button>
                <ul class="dropdown-menu bg-transparent border-0">
                  <li>
                    <button type="button" class="btn btn-danger" @click="$emit('quotation:reject', props.row)">Confirmer</button>
                  </li>
                </ul>
              </div>
              <button v-if="!props.row.acceptedAt && props.row.document.ownedBy.username !== $store.state.login.user.userData.username  && $store.getters['login/isAgency']"
                      class="btn btn-success" @click="$emit('quotation:validate', props.row)">
                Accepter le devis
              </button>
            </div>
            <div v-else-if="props.column.field === 'mainPurpose'">
              <div class="m-2">
                {{ props.formattedRow[props.column.field] }}
              </div>
              <SimpleAccordion v-if="hiddenFields.length > 0">
                <template v-slot:accordionTitle>Plus d'infos</template>
                <template v-slot:accordionContent>
                  <div class="p-2">
                    <div v-if="hiddenFields.includes('amountWoTax')" class="mb-3">
                      <p>Montant HT : {{ props.formattedRow['amountWoTax'] }}<br>
                        Montant TTC : {{ props.formattedRow['amountWithTax'] }}</p>
                    </div>
                    <div v-if="hiddenFields.includes('document.ownedBy')" class="mb-3">
                      <p>Par : {{ props.formattedRow['document.ownedBy'] }}</p>
                    </div>
                    <div v-if="hiddenFields.includes('createdAt')" class="mb-3">
                      <p>Le : {{ props.formattedRow['createdAt'] }}</p>
                    </div>
                  </div>
                </template>
              </SimpleAccordion>
            </div>
            <div v-else>
              {{ props.formattedRow[props.column.field] }}
            </div>
          </template>
        </vue-good-table>
      </template>
    </SimpleAccordion>
    <BlankModal ref="quotationModal" :modal-id="quotationModalId">
      <template #modalHeader>Ajouter un devis</template>
      <template #modalBody>
        <new-quotation-vue ref="newQuotation" @add-quotation="quotation => $emit('quotation:add', quotation)"></new-quotation-vue>
      </template>
      <template v-slot:modalFooterExtra>
        <button class="btn btn-primary" @click="validInput">Ajouter</button>
      </template>
    </BlankModal>
  </div>
</template>

<script>
import backendApi from "@/backend/api";
import BlankModal from "@/components/BlankModal";
import store from "@/store";
import SimpleAccordion from "@/components/SimpleAccordion";
import Vue from "vue";
import random from "@/helpers/random";
import NewQuotationVue from './quotations/NewQuotation.vue';
import {mapActions} from "vuex"

export default {
  name: "QuotationsList",
  components: {
    SimpleAccordion,
    BlankModal,
    NewQuotationVue
  },
  emits: ['quotation:reject', 'quotation:validate', 'quotation:add'],
  data() {
    return {
      quotationModalId: random.htmlId()
    }
  },
  props: {
    quotations: {
      required: false,
      default: () => [],
    }
  },
  computed: {
    columns: function () {
      return [
        {
          label: 'Objet',
          field: 'mainPurpose',
          hidden: this.shouldBeHidden('xs'),
        },
        {
          label: 'Montant HT',
          field: 'amountWoTax',
          hidden: this.shouldBeHidden('sm'),
          formatFn: Vue.filter('amountFormat'),
        },
        {
          label: 'Montant TTC',
          field: 'amountWithTax',
          hidden: this.shouldBeHidden('sm'),
          formatFn: Vue.filter('amountFormat'),
        },
        {
          label: 'Envoyé par',
          field: 'document.ownedBy',
          hidden: this.shouldBeHidden('xl'),
          formatFn: this.ownedByDisplay,
        },
        {
          label: 'Le',
          field: 'createdAt',
          hidden: this.shouldBeHidden('xl'),
          formatFn: Vue.filter('humanReadableDate'),
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '1%',
        },
      ]
    },
    hiddenFields: function () {
      let hiddenFields = []
      this.columns.forEach(column => {
        if (column.hidden) {
          hiddenFields.push(column.field)
        }
      })

      return hiddenFields
    },
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    shouldBeHidden: function (at) {
      const bp = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'big', 'giga',]
      if (!bp.includes(at)) {
        return false;
      }

      return !(bp.indexOf(at) <= bp.indexOf(this.$mq))
    },
    ownedByDisplay(ownedBy) {
      if (!ownedBy) {
        return 'legacy - unknown'
      }

      return `${ownedBy.username} - ${ownedBy.userInfos.lastName} ${ownedBy.userInfos.firstName}`
    },
    downloadDocument(identifier, originalName) {
      backendApi
          .downloadDocument(store.state.login.user.token, identifier, originalName)
    },
    openModalQuotationAdd() {
      this.$bootstrap.Modal.getOrCreateInstance(this.$refs.quotationModal.$el).show()
    },
    hideModalQuotationAdd() {
      this.$bootstrap.Modal.getOrCreateInstance(this.$refs.quotationModal.$el).hide()
      this.$refs.newQuotation.initInput()
    },
    validInput() {
      this.$refs.newQuotation.submit()
    }
  }
}
</script>

<style scoped></style>
