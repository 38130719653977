<template>
  <div class="uk-margin">
    <label v-if="iterationKey === null" class="uk-form-label">Email</label>
    <div class="uk-form-controls uk-grid-collapse uk-child-width-expand@s" uk-grid>
      <input class="uk-input uk-padding-small uk-padding-remove-vertical" type="text" placeholder="Exemple: mister@domain.com" v-model="email.value">
      <vk-button
          v-if="iterationKey !== null"
          type="danger" size="small"
          class="uk-width-auto uk-padding uk-padding-remove-vertical"
          @click="$emit('deleteEmail', iterationKey)"
      >Supprimer</vk-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Email",
  props: {
    email: {
      required: false,
      default: () => ({
        value: '',
      })
    },
    iterationKey: {
      required: false,
      default: () => null,
    }
  }
}
</script>

<style scoped>

</style>
