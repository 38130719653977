import backendApi from '../../backend/api'

export default {
    namespaced: true,
    state: () => ({
        list: [],
        listIsUpdating: false,
        isLoading: false
    }),
    mutations: {
        UPDATE_PROPERTIES(store, payload) {
            store.list = payload
        },
    },
    actions: {
        updateProperties(context) {
            context.state.isLoading = true
            context.state.listIsUpdating = true
            return backendApi
            .getProperties(context.rootState.login.user.token)
            .then((data) => {
                context.commit('UPDATE_PROPERTIES', data)
                context.state.isLoading = false
            })
            .catch(() => {})
            .finally(() => {
                context.state.listIsUpdating = false
            })
        },
    },
    getters: {
    },
}
