<template>
  <div>
    <Transition name="fade">
      <!-- step 1 send email -->
      <div v-if="step == 1" class="uk-margin-large-left uk-margin-large-right uk-margin-large-top uk-margin-large-bottom">
        <h1>Récupération de mot de passe</h1>
        <div uk-alert class="uk-alert-danger" v-if="error && step == 1">
          {{ error }}
        </div>
        <form>
          <div class="uk-margin">
            <input id="email" name="email" class="uk-input uk-form-width-large uk-width uk-border-rounded" type="email" v-model="email" placeholder="Adresse email">
          </div>
          <div class="uk-align-right">
            <button type="button" class="btn btn-primary" :disabled="$store.state.resetPassword.resetInProgress || !email || (email && !emailFormatValide)" @click="askResetPassword({ email })">
              Envoyer
              <div class="ms-2" uk-spinner="ratio:0.5" v-if="$store.state.resetPassword.resetInProgress"></div>
            </button>
          </div>
          <div class="uk-align-right">
            <button type="button" class="btn btn-primary" href="#" @click="toggleResetPassword()">Se connecter</button>
          </div>
        </form>
      </div>

      <!-- step 2 verification code -->
      <div v-else-if="step == 2" class="uk-margin-large-left uk-margin-large-right uk-margin-large-top uk-margin-large-bottom">
        <h1>Vérification de code</h1>
        <div uk-alert class="uk-alert-success" v-if="success && step == 2">
          {{ success }}
        </div>
        <div uk-alert class="uk-alert-danger" v-if="error && step == 2">
          {{ error }}
        </div>
        <form>
          <div class="uk-margin">
            <input id="code" name="code" class="uk-input uk-form-width-large uk-width uk-border-rounded" type="text" v-model="verificationCode" placeholder="Code de vérification">
          </div>

          <div class="uk-align-right">
            <button type="button" class="btn btn-primary" :disabled="$store.state.resetPassword.resetInProgress || !verificationCodeValide" @click="verificationConfirmationCode({ verificationCode })">
              Vérifier
              <div class="ms-2" uk-spinner="ratio:0.5" v-if="$store.state.resetPassword.resetInProgress"></div>
            </button>
          </div>

          <div class="uk-align-right">
            <button type="button" class="btn btn-primary" :disabled="$store.state.resetPassword.resetInProgress" @click="resendVerificationCode()">
              Renvoyer le code
              <div class="ms-2" uk-spinner="ratio:0.5" v-if="$store.state.resetPassword.resetInProgress"></div>
            </button>
          </div>

          <div class="uk-align-right">
            <button type="button" class="btn btn-primary" href="#" @click="toggleResetPassword()">Se connecter</button>
            <button type="button" class="btn btn-primary uk-margin-left" href="#" @click="backStep()">Retour</button>
          </div>
        </form>
      </div>

      <!-- step 3 update password -->
      <div v-else class="uk-margin-large-left uk-margin-large-right uk-margin-large-top uk-margin-large-bottom">
        <h1>Veuillez entrer un nouveau mot de passe</h1>
        <div uk-alert class="uk-alert-danger" v-if="error && step == 3">
          {{ error }}
        </div>
        <form>
          <div class="uk-margin">
            <input id="password" name="password" class="uk-input uk-form-width-large uk-width uk-border-rounded" type="password" v-model="password" placeholder="Nouveau mot de passe">
          </div>

          <div class="uk-margin">
            <input id="confirmPassword" name="confirmPassword" class="uk-input uk-form-width-large uk-width uk-border-rounded" type="password" v-model="confirmPassword" placeholder="Confirmer nouveau mot de passe">
          </div>

          <div class="uk-align-right">
            <button type="button" class="btn btn-primary" :disabled="$store.state.resetPassword.resetInProgress || !passwordValide" @click="validateResetPassword({ password, confirmPassword })">
              Valider
              <div class="ms-2" uk-spinner="ratio:0.5" v-if="$store.state.resetPassword.resetInProgress"></div>
            </button>
          </div>
          <div class="uk-align-right">
            <button type="button" class="btn btn-primary" href="#" @click="toggleResetPassword()">Se connecter</button>
          </div>
        </form>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "LoginModalResetPassword",
  data: () => {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      verificationCode: ''
    }
  },
  computed: {
    ...mapState('resetPassword', [
      'isResetPassword',
      'resetInProgress',
      'step',
      'error',
      'success'
    ]),
    passwordValide() {
      if (
        this.password
        && this.confirmPassword
      ) return true;

      return false;
    },

    emailFormatValide() {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.email.match(validRegex)
    },

    verificationCodeValide() {
      if (
        this.verificationCode
        && this.verificationCode.length == 6
      ) return true;
      return false
    }
  },
  methods: {
    ...mapActions('resetPassword', [
      'toggleResetPassword',
      'askResetPassword',
      'validateResetPassword',
      'verificationConfirmationCode',
      'backStep',
      'resendVerificationCode'
    ])
  }
}
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.2s ease-out;
}

.fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
