<template>
    <div id="app">
        <vk-sticky :top="0" width-element="100%">
            <vk-navbar class="uk-margin" :style="{'z-index': '1100'}">
                <vk-navbar-toggle title="Menu" style="color: #2cb4db" @click="displayMenu = true"></vk-navbar-toggle>
                <vk-navbar-logo slot="center" style="color: #2cb4db">
                    <img alt="Workr" src="./assets/workr-logo.png" style="width: 8.0rem; margin-right: 0.07rem;">
                </vk-navbar-logo>
                <div slot="right" class="me-4">
                    <button type="button" class="btn btn-light-customed text-primary position-relative"
                            @click="displayRemoteNotifications = true" style="color: #ffffff !important;">
                        <font-awesome-icon icon="fa-solid fa-bell"/>
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {{ remoteNotifications.length }}
              <span class="visually-hidden">unread messages</span>
            </span>
                    </button>
                </div>
            </vk-navbar>
        </vk-sticky>
        <router-view/>
        <vk-offcanvas-content>
            <vk-offcanvas overlay mode="reveal" :show.sync="displayMenu">
                <vk-offcanvas-close @click="displayMenu = false"></vk-offcanvas-close>
                <nav-menu v-on:nav:close="displayMenu = false"/>
            </vk-offcanvas>
        </vk-offcanvas-content>
        <vk-offcanvas-content>
            <vk-offcanvas flipped overlay mode="reveal" :show.sync="displayRemoteNotifications">
                <vk-offcanvas-close @click="displayRemoteNotifications = false"></vk-offcanvas-close>
                <h5 class="text-white mb-4">Notifications</h5>
                <div
                    v-for="(remoteNotification, key) in remoteNotifications"
                    :key="remoteNotification.type + '-' + key"
                    class="card mb-4"
                    style="font-size: 1rem;"
                >
                    <div class="card-body">
                        <h5 class="card-title text-uppercase text-muted" style="font-size: smaller">Nouveau message</h5>
                        <h6 class="card-subtitle mb-2 text-muted small">
                            Le {{ remoteNotification.messageReadHistory.createdAt | humanReadableDate }}
                        </h6>
                        <p class="card-text text-muted">
                            {{ remoteNotification.messageReadHistory.message.value.substring(0, 60) + '...' }}
                        </p>
                        <div class="d-flex justify-content-between gap-2">
                            <button
                                v-if="remoteNotification.messageReadHistory.ticket"
                                type="button" class="btn btn-outline-primary btn-sm"
                                @click="$router.push({
                  name: 'ticket_view',
                  params: {ticketId: remoteNotification.messageReadHistory.ticket.id}
                }); displayRemoteNotifications = false"
                            >
                                Voir le ticket {{ remoteNotification.messageReadHistory.ticket.id }}
                            </button>
                            <button
                                v-else-if="remoteNotification.messageReadHistory.missionOrder"
                                type="button" class="btn btn-outline-primary btn-sm"
                                @click="$router.push({
                  name: 'mission_order_view',
                  params: {missionOrderId: remoteNotification.messageReadHistory.missionOrder.id}
                }); displayRemoteNotifications = false"
                            >
                                Voir l'ordre {{ remoteNotification.messageReadHistory.missionOrder.id }}
                            </button>
                            <button
                                type="button" class="btn btn-outline-danger btn-sm"
                                @click="deleteRemoteNotification(remoteNotification.messageReadHistory.id)"
                            >
                                X
                            </button>
                        </div>
                    </div>
                </div>
            </vk-offcanvas>
        </vk-offcanvas-content>
        <vk-notification :messages.sync="notifications"></vk-notification>
        <login-modal/>
    </div>
</template>

<script>
import NavMenu from "@/components/NavMenu";
import LoginModal from "@/components/LoginModal";
import {mapActions, mapState} from "vuex";
import store from "@/store";

export default {
    components: {
        LoginModal,
        NavMenu,
    },
    data: () => ({
        displayMenu: false,
        displayRemoteNotifications: false,
        loginHeartBeatDelay: 1000 * 60 * 15, // 15 minutes
    }),
    computed: {
        ...mapState('notifications', {storeNotifications: 'notifications'}),
        ...mapState('remoteNotifications', {remoteNotifications: 'notifications'}),
        notifications: {
            get() {
                return this.storeNotifications
            },
            set(notifications) {
                this.setNotifications(notifications)
            }
        }
    },
    watch: {
        $mq: {
            immediate: true,
            handler(newValue) {
                const scale = {
                    xs: 0.65,
                    sm: 0.7,
                    md: 0.8,
                    lg: 0.9,
                    xl: 1,
                    xxl: 1,
                    xxxl: 1,
                    big: 1,
                    giga: 1,
                }

                document.documentElement.style.fontSize = (scale[newValue] || 1) + 'rem'
            }
        }
    },
    methods: {
        ...mapActions('dashboard', ['getDashboardData', 'getDashboardConfigData']),
        ...mapActions('notifications', ['setNotifications']),
        ...mapActions('login', ['loginUpdate']),
        ...mapActions('remoteNotifications', {deleteRemoteNotification: 'deleteNotification'}),
        loginUpdateHeartBeat() {
            this.loginUpdate()
            setTimeout(this.loginUpdateHeartBeat, this.loginHeartBeatDelay)
        },
    },
    mounted() {
        this.loginUpdateHeartBeat()
        this.getDashboardData()
        this.getDashboardConfigData()
    }
}
</script>


<style lang="scss">
#app {
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
    //text-align: center;
    //color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.vgt-inner-wrap * {
    font-size: 0.9rem !important;
}

.vgt-table {
    font-size: 0.9rem !important;
}

.footer__navigation * {
    margin: 0 !important;
}

.uk-offcanvas {
    z-index: 1200 !important;
}

.uk-notification {
    z-index: 1300 !important;
}

.modal {
    z-index: 1250 !important;
}

.uk-modal {
    z-index: 1260 !important;
}

.btn-light-customed {
    color: #ffffff !important;
    background-color: #2cb4db !important;
}

.uk-navbar-container:not(.uk-navbar-transparent) {
    background: #ffffff !important;
    border-bottom: solid;
    border-bottom-color: #2cb4db;
}

.uk-offcanvas-bar {
    background-color: #ffffff !important;
}

.uk-offcanvas-close {
    margin-top: 15px !important;
}

.uk-nav-default .uk-nav-sub .active-on-click a {
    color: #2cb4db !important;
}

.uk-nav-default > li > a {
    color: #999 !important;
}

.uk-nav-default > li > .active-on-click > a {
    color: #999 !important;
}

.uk-nav-default.uk-nav-parent-icon.uk-nav > li > a.active-on-click {
    color: #2cb4db !important;
}

.active-on-click {
    font-weight: bold !important;
    color: #2cb4db !important;
    border-right: solid !important;
    border-right-color: #2cb4db !important;
}

.btn-primary {
    color: #ffffff !important;
}

.list-group-item-customed {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: #2cb4db;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item-blue {
    color: #ffffff;
    background-color: #2cb4db;
}

.accordion-button:not(.collapsed) {
    color: #ffffff !important;
    background-color: #2cb4db !important;
}

.vgt-table thead th {
    background: #ffffff !important;
}
</style>
