<template>
  <div class="uk-card uk-card-default uk-card-hover uk-border-rounded uk-padding-small uk-margin-bottom" style="border: #999999 solid 1px">
    <div class="uk-margin" v-if="iterationKey !== null">
      <vk-button type="danger" class="uk-button-small uk-border-rounded" @click="$emit('deleteContact', iterationKey)">Supprimer le contact</vk-button>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Nom complet </label>
      <div class="uk-form-controls">
        <input class="uk-input" type="text" placeholder="Exemple: Mr Martin Pascal (locataire)" v-model="contact.fullName">
      </div>
    </div>
    <div class="uk-card-body uk-padding-small">
      <div class="uk-card uk-card-default uk-card-hover uk-border-rounded uk-padding-small uk-margin-bottom" style="border: #999999 solid 1px">
        <h5>
          <span class="uk-margin-small-right">Numéros de téléphone</span>
          <vk-button
              type="primary" size="small"
              class="uk-border-rounded"
              @click="addPhoneNumber"
          >Ajouter</vk-button>
        </h5>
        <PhoneNumber v-for="(phoneNumber, index) in contact.phoneNumbers"
                     :key="'phoneNumber' + index"
                     :phone-number.sync="phoneNumber"
                     :iteration-key="index"
                     v-on:deletePhoneNumber="deletePhoneNumber"
        />
      </div>
      <div class="uk-card uk-card-default uk-card-hover uk-border-rounded uk-padding-small" style="border: #999999 solid 1px">
        <h5>
          <span class="uk-margin-small-right">Emails</span>
          <vk-button
              type="primary" size="small"
              class="uk-border-rounded"
              @click="addEmail"
          >Ajouter</vk-button>
        </h5>
        <Email v-for="(email, index) in contact.emails"
               :key="'phoneNumber' + index"
               :iterationKey="index"
               :email.sync="email"
               v-on:deleteEmail="deleteEmail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PhoneNumber from "@/components/PhoneNumber";
import Email from "@/components/Email";
export default {
  name: "Contact",
  components: {Email, PhoneNumber},
  data: () => ({
    defaultProps: {
      phoneNumber: PhoneNumber.props.phoneNumber.default,
      email: Email.props.email.default,
    },
  }),
  props: {
    contact: {
      required: false,
      default: () => ({
        fullName: '',
        emails: [],
        phoneNumbers: [],
      }),
    },
    iterationKey: {
      required: false,
      default: () => null,
    },
  },
  methods: {
    addEmail() {
      this.contact.emails.push(this.defaultProps.email())
    },
    addPhoneNumber() {
      this.contact.phoneNumbers.push(this.defaultProps.phoneNumber())
    },
    deleteEmail(key) {
      if (key !== null) {
        this.$delete(this.contact.emails, key)
      }
    },
    deletePhoneNumber(key) {
      if (key !== null) {
        this.$delete(this.contact.phoneNumbers, key)
      }
    }
  }
}
</script>

<style scoped>

</style>
