import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {Capacitor} from '@capacitor/core';

// uikit things
import Vuikit from 'vuikit'
import VuikitIcons from '@vuikit/icons'
import './assets/scss/uikit-theme.scss'
import 'uikit/dist/js/uikit.min'

Vue.use(Vuikit)
Vue.use(VuikitIcons)

// bootstrap
import './assets/scss/bootstrap-custom.scss'
import '@popperjs/core'
import * as bootstrap from 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.scss'

// good tables things
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

// fontawesome
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import {faFileArrowDown, faPlus, faScrewdriverWrench, faUsers, faLock, faEyeSlash, faGear, faPen, faBell, faCamera, faCirclePlay} from '@fortawesome/free-solid-svg-icons'
import {faEye, faUser, faClipboard, faPenToSquare, faFlag, faCircleQuestion, faFloppyDisk, faTrashCan} from '@fortawesome/free-regular-svg-icons'
/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(
    faEye,
    faFileArrowDown,
    faPlus,
    faScrewdriverWrench,
    faUsers,
    faUser,
    faClipboard,
    faPenToSquare,
    faFlag,
    faCircleQuestion,
    faLock,
    faEyeSlash,
    faGear,
    faPen,
    faFloppyDisk,
    faTrashCan,
    faBell,
    faCamera,
    faCirclePlay
)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'leaflet/dist/leaflet.css'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'
import leaflet from 'leaflet'
import {GestureHandling} from "leaflet-gesture-handling";

leaflet.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

import VueMq from 'vue-mq'

Vue.use(VueMq, {
    breakpoints: {
        xs: 576,
        sm: 749,
        md: 929,
        lg: 1106,
        xl: 1272,
        xxl: 1425,
        xxxl: 1568,
        big: 1694,
        giga: Infinity,
    }
})

Vue.config.productionTip = false

Vue.prototype.$bootstrap = bootstrap
Vue.prototype.$leaflet = leaflet
Vue.prototype.$env = process.env
Vue.prototype.$capacitor = Capacitor

Vue.filter('humanReadableDate', function (value) {
    if (!value) return ''
    const date = new Date(value)
    return new Intl.DateTimeFormat(navigator.language, {dateStyle: 'medium', timeStyle: 'medium'}).format(date)
})

Vue.filter('amountFormat', function (value) {
    if (!value) return ''
    return new Intl.NumberFormat(navigator.language, {style: 'currency', currency: 'EUR'}).format(value)
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

/** multiselect */
import Multiselect from "vue-multiselect";

Vue.component('multiselect', Multiselect)
/** ----------- */

/** Apexcharts */
import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);
Vue.component('apexcharts', VueApexCharts);
/** ---------- */