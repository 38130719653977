<template>
  <li>
    <a href="#"
       @click.prevent="$router.push({name: 'status'}); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'status'}"
       style="color: white"
    >
      <font-awesome-icon
          icon="fa-regular fa-flag"
          class="uk-margin-small-right"
      />
      Gestion des statuts
    </a>
  </li>
</template>

<script>
export default {
  name: "ItemManageStatus"
}
</script>

<style scoped>
.uk-nav-default .uk-nav-sub a {
  color: #999 !important;
}
</style>
