import backendApi from '../../backend/api'

export default {
    namespaced: true,
    state: () => ({
        list: [],
        listIsUpdating: false,
        buildingTradesList: [],
    }),
    mutations: {
        UPDATE_WORKERS(store, payload) {
            store.list = payload
        },
        UPDATE_BUILDING_TRADES(store, payload) {
            store.buildingTradesList = payload
        },
    },
    actions: {
        updateWorkers(context) {
            context.state.listIsUpdating = true
            return backendApi
            .getWorkersAccount(context.rootState.login.user.token)
            .then((data) => {
                context.commit('UPDATE_WORKERS', data)
            })
            .catch(() => {})
            .finally(() => {
                context.state.listIsUpdating = false
            })
        },
        updateBuildingTrades(context) {
            return backendApi
            .getBuildingTradesList(context.rootState.login.user.token)
            .then((data) => {
                context.commit('UPDATE_BUILDING_TRADES', data)
            })
            .catch(() => {})
        },
    },
    getters: {
    },
}
