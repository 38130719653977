<template>
  <div>
    <div class="align-content-center">
      <img alt="Workr" src="./../assets/workr-logo.png" class="uk-logo uk-logo-customed">
    </div>
    <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
      <li class="uk-parent">
        <a href="#" style="color: white">
          <font-awesome-icon icon="fa-regular fa-clipboard" class="uk-margin-small-right"/>
          Dashboard
        </a>
        <ul class="uk-nav-sub">
          <vk-nav-item @click="$router.push({name: 'dashboard_config'}); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'dashboard_config'}" title="Modifier"/>
          <vk-nav-item @click.prevent="$router.push({name: 'root'}); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'root'}" title="Voir"/>
        </ul>
      </li>
      <vk-nav-item-divider/>
    </ul>
    <ItemsAgencyAccount
        v-if="user.userData.accountType === 'agencyAccount'"
        v-on="$listeners"
    />
    <ItemsWorkerAccount
        v-if="user.userData.accountType === 'workerAccount'"
        v-on="$listeners"
    />
    <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
      <vk-nav-item-divider/>
      <li>
        <a href="#"
           @click.prevent="$router.push({name: 'parameters'}); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'parameters'}"
           style="color: white"
        >
          <font-awesome-icon icon="fa-solid fa-gear" class="uk-margin-small-right"/>
          Paramètres</a>
      </li>
    </ul>
    <button type="button" class="btn btn-danger mt-4" @click="logout(); $emit('nav:close')">Déconnexion</button>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ItemsAgencyAccount from "@/components/menuItems/ItemsAgencyAccount";
import ItemsWorkerAccount from "@/components/menuItems/ItemsWorkerAccount";

export default {
  name: "NavMenu",
  components: {ItemsWorkerAccount, ItemsAgencyAccount},
  emits: ['nav:close'],
  computed: {
    ...mapState('login', ['user'])
  },
  methods: {
    ...mapActions('login', ['logout'])
  }
}
</script>

<style scoped>
.uk-nav-default .uk-nav-sub a {
  color: #999 !important;
}

.align-content-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.uk-logo-customed {
  width: 8.0rem;
  margin-right: 0.07rem;
  position: relative;
}
</style>
