<template>
  <li class="uk-parent">
    <a href="#" style="color: white">
      <font-awesome-icon
          icon="fa-solid fa-users"
          class="uk-margin-small-right"
      />
      Utilisateurs
    </a>
    <ul class="uk-nav-sub">
      <vk-nav-item @click="$router.push({name: 'users'}); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'users'}" title="Lister / Modifier"/>
      <vk-nav-item @click="$router.push({name: 'user_new'}); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'user_new'}" title="Ajouter"/>
    </ul>
  </li>
</template>

<script>
export default {
  name: "ItemManageUsers"
}
</script>

<style scoped>
.uk-nav-default .uk-nav-sub a {
  color: #999 !important;
}
</style>
