<template>
  <SimpleAccordion>
    <template v-slot:accordionTitle>
      Rattachement des utilisateurs
    </template>
    <template v-slot:accordionContent>
      <vue-good-table
          ref="vueTable"
          @on-selected-rows-change="selectionHasChanged"
          :columns="columns"
          :rows="companyUserList"
          :select-options="{
            enabled: true,
            selectionText: 'Utilisateurs sélectionnées',
            clearSelectionText: 'effacer la sélection',
          }"
      >
        <template v-slot:table-actions>
          <button
              v-if="userSelectionHasChanged"
              class="btn btn-warning btn-sm me-3"
              @click="updateUserSelection"
              :disabled="updateUserSelectionIsInProgress"
          >
            Envoyer la mise à jour
            <div class="spinner-border text-black spinner-border-sm ms-2 me-2" role="status" v-if="updateUserSelectionIsInProgress">
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </template>
      </vue-good-table>
    </template>
  </SimpleAccordion>
</template>

<script>
// todo improve this component ! A lot !!!
import SimpleAccordion from "@/components/SimpleAccordion";
export default {
  name: "AttachWorkerUserAccordion",
  components: {SimpleAccordion},
  data: () => ({
    columns: [
      {
        label: 'Utilisateur',
        field: 'username',
      },
      {
        label: 'Prénom',
        field: 'userInfos.firstName',
      },
      {
        label: 'Nom',
        field: 'userInfos.lastName',
      }
    ],
    updateUserSelectionIsInProgress: false,
  }),
  props: {
    companyUserList: {
      required: false,
      default: () => [],
    },
    attachedUsers: {
      required: false,
      default: () => [],
    },
    updateUserSelectionMethod: {
      type: Function,
      required: false,
      default: () => Promise.resolve()
    },
  },
  computed: {
    attachedFilteredFromUserList() {
      console.log('attachedFilteredFromUserList')
      console.log(this.attachedUsers.filter(attachedUser => this.companyUserList.find(user => user.id === attachedUser.id)))
      return this.attachedUsers.filter(attachedUser => this.companyUserList.find(user => user.id === attachedUser.id))
    },
  },
  watch: {
    attachedFilteredFromUserList: {
      immediate: true,
      deep: true,
      handler() {
        this.syncAlreadySelectedUsers()
      }
    }
  },
  methods: {
    selectedRows() {
      console.log('selectedRows')
      try {
        return this.$refs.vueTable.selectedRows
      } catch (error) {
        return []
      }
    },
    userSelectionHasChanged() {
      console.log('userSelectionHasChanged')
      let attached = []
      let selected = []
      this.attachedFilteredFromUserList.forEach(user => {
        attached.push({id: user.id})
      })
      this.selectedRows().forEach(user => {
        selected.push({id: user.id})
      })

      console.log(
          JSON.stringify(attached),
          JSON.stringify(selected),
      )

      return JSON.stringify(attached) !== JSON.stringify(selected)
    },
    syncAlreadySelectedUsers() {
      console.log('syncAlreadySelectedUsers')
      if (this.$refs.vueTable) {
        this.$refs.vueTable.filteredRows.forEach((headerRow) => {
          headerRow.children.forEach((row) => {
            if (this.attachedFilteredFromUserList.find((user) => user.id === row.id)) {
              this.$set(row, 'vgtSelected', true)
            } else {
              this.$delete(row, 'vgtSelected')
            }
          })
        })
      }
    },
    updateUserSelection() {
      this.updateUserSelectionIsInProgress = true
      this
          .updateUserSelectionMethod(this.$refs.vueTable.selectedRows)
          .finally(() => {
            this.updateUserSelectionIsInProgress = false
          })
    },
    selectionHasChanged() {
      // console.log('selectionHasChanged')
    },
  },
  mounted() {
    this.syncAlreadySelectedUsers()
  }
}
</script>

<style scoped>

</style>
