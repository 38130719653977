import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import('../views/HomeDashboard.vue'),
  },
  {
    path: '/properties',
    name: 'properties',
    component: () => import('../views/Properties.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.login.user.userData?.accountType !== 'agencyAccount') {
        return next({name: 'root'})
      }
      next()
    },
  },
  {
    path: '/property',
    name: 'property_new',
    component: () => import('../views/PropertyCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.login.user.userData?.accountType !== 'agencyAccount') {
        return next({name: 'root'})
      }
      next()
    },
  },
  {
    path: '/property/:propertyId',
    name: 'property_edit',
    component: () => import('../views/PropertyEdit.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.login.user.userData?.accountType !== 'agencyAccount') {
        return next({name: 'root'})
      }
      next()
    },
  },
  {
    path: '/workers',
    name: 'workers_list',
    component: () => import('../views/WorkersList.vue'),
    beforeEnter: (to, from, next) => {
      if (!['agencyAccount', 'superManagerAccount'].includes(store.state.login.user.userData?.accountType)) {
        return next({name: 'root'})
      }
      next()
    },
  },
  {
    path: '/worker',
    name: 'worker_add',
    component: () => import('../views/WorkerAdd.vue'),
    beforeEnter: (to, from, next) => {
      if (!['agencyAccount', 'superManagerAccount'].includes(store.state.login.user.userData?.accountType)) {
        return next({name: 'root'})
      }
      next()
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('../views/Tickets.vue'),
  },
  {
    path: '/ticket/:ticketId',
    name: 'ticket_view',
    component: () => import('../views/TicketView.vue'),
  },
  {
    path: '/ticket',
    name: 'ticket_new',
    component: () => import('../views/TicketCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.login.user.userData?.accountType !== 'agencyAccount') {
        return next({name: 'root'})
      }
      next()
    },
  },
  {
    path: '/mission-orders',
    name: 'mission_orders',
    component: () => import('../views/MissionOrders.vue'),
  },
  {
    path: '/mission-order/:missionOrderId',
    name: 'mission_order_view',
    component: () => import('../views/MissionOrderView.vue'),
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('../views/Status.vue'),
  },
  {
    path: '/parameters',
    name: 'parameters',
    component: () => import('../views/Parameters.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/Users.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.state.login.user.userData.roles.includes('admin')) {
        return next({name: 'root'})
      }
      next()
    },
  },
  {
    path: '/user',
    name: 'user_new',
    component: () => import('../views/UserCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.state.login.user.userData.roles.includes('admin')) {
        return next({name: 'root'})
      }
      next()
    },
  },
  {
    path: '/user/:userId',
    name: 'user_edit',
    component: () => import('../views/UserEdit.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.state.login.user.userData.roles.includes('admin')) {
        return next({name: 'root'})
      }
      next()
    },
  },
  {
    path: '/video-formations',
    name: 'video-formations',
    component: () => import('../views/VideoFormation.vue')
  },
  {
    path: '/dashboard-config',
    name: 'dashboard_config',
    component: () => import('../views/DashboardConfig.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.login.user.token && to.name !== 'root') {
    return next({name: 'root'})
  }
  next()
})

export default router
