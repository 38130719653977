import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import notifications from "./modules/notifications";
import properties from "./modules/properties";
import workers from "./modules/workers";
import dashboard from "./modules/dashboard";
import status from "./modules/status";
import users from "./modules/users";
import tickets from "./modules/tickets";
import missionOrders from "./modules/missionOrders";
import localNotifications from "./modules/localNotifications";
import remoteNotifications from "./modules/remoteNotifications";
import parameters from "@/store/modules/parameters";
// import videoFormation from './modules/videoFormation';
import resetPassword from './modules/resetPassword';
import {LocalNotifications} from "@capacitor/local-notifications";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    notifications,
    properties,
    workers,
    dashboard,
    status,
    users,
    tickets,
    missionOrders,
    localNotifications,
    remoteNotifications,
    parameters,
    // videoFormation,
    resetPassword
  }
})

store.watch(
(state) => state.tickets.currentTicket,
() => {
  store.dispatch('tickets/clearMainChatTicker').then(() => store.dispatch('tickets/updateMainChatTicker'))
  store.dispatch('tickets/clearDocumentsTicker').then(() => store.dispatch('tickets/updateDocumentsTicker'))
},
);

store.watch(
(state) => state.missionOrders.currentMissionOrder,
() => {
  store.dispatch('missionOrders/clearMainChatTicker').then(() => store.dispatch('missionOrders/updateMainChatTicker'))
  store.dispatch('missionOrders/clearDocumentsTicker').then(() => store.dispatch('missionOrders/updateDocumentsTicker'))
},
);

LocalNotifications.requestPermissions()
.then(() => LocalNotifications.checkPermissions())
.then(({display}) => {
  console.log(display)
  if (display !== 'granted') {
    store.dispatch('notifications/addNotification', {status: 'warning', message: 'Autorisez les notifications pour cette application si vous souhaitez en recevoir'})
  }
})

store.watch(
(state) => state.localNotifications.notifications,
() => {
  LocalNotifications.checkPermissions()
  .then(({display}) => {
    console.log(display)
    if (display === 'granted') {
      while (store.state.localNotifications.notifications.length) {
        let notification = store.state.localNotifications.notifications.pop()
        LocalNotifications.schedule({notifications: [notification]})
      }
    }
  })
},
);

store.watch(
(state) => state.login.user.token,
(token) => {
  if (!token) {
    store
    .dispatch('remoteNotifications/clearNotificationTicker')
    .then(() => store.commit('remoteNotifications/CLEAR_NOTIFICATIONS'))
  } else {
    store.dispatch('remoteNotifications/updateNotificationTicker')
  }
},
);

export default store
