import backendApi from '../../backend/api'
import Ticket from "@/components/Ticket";
import store from "@/store";
import Vue from "vue";

export default {
    namespaced: true,
    state: () => ({
        currentTicketUpdateIsPending: false,
        currentTicketLastError: false,
        currentTicket: Ticket.props.ticket.default(),
        updateMainChatTicker: null,
        intervalMainChatTicker: 10000,
        updateDocumentsTicker: null,
        intervalDocumentsTicker: 20000,
    }),
    mutations: {
        UPDATE_CURRENT_TICKET(store, payload) {
            store.currentTicket = payload
        },
        UPDATE_MAIN_CHAT(store, payload) {
            store.currentTicket.mainChat = payload
        },
        UPDATE_PRIVATE_CHAT(store, {privateChatId, payload}) {
            for (let i = 0; i < store.currentTicket.privateChats.length; i++) {
                if (store.currentTicket.privateChats[i].id === privateChatId) {
                    Vue.set(store.currentTicket.privateChats, i, payload)
                    return
                }
            }
        },
        UPDATE_DOCUMENTS(store, payload) {
            store.currentTicket.documents = payload
        },
    },
    actions: {
        setCurrentTicket(context, id) {
            context.state.currentTicketUpdateIsPending = true
            return backendApi
                .getTicket(context.rootState.login.user.token, id)
                .then((data) => {
                    context.commit('UPDATE_CURRENT_TICKET', data)
                })
                .finally(() => {
                    context.state.currentTicketUpdateIsPending = false
                })
        },
        updateMainChat(context) {
            let ticketId = context.state.currentTicket.id
            if (!ticketId) {
                return Promise.resolve()
            }
            return backendApi
            .getTicketMainChat(store.state.login.user.token, ticketId)
            .then(data => {
                context.commit('UPDATE_MAIN_CHAT', data)
            })
        },
        updateMainChatTicker(context) {
            context.dispatch('updateMainChat')
            if (!context.state.updateMainChatTicker && !!context.state.currentTicket.id) {
                context.state.updateMainChatTicker = setInterval(
                    () => context.dispatch('updateMainChat'),
                    context.state.intervalMainChatTicker
                )
            }
        },
        clearMainChatTicker(context) {
            if (context.state.updateMainChatTicker) {
                clearInterval(context.state.updateMainChatTicker)
                context.state.updateMainChatTicker = null
            }
        },
        updatePrivateChat(context, privateChatId) {
            let ticketId = context.state.currentTicket.id
            if (!ticketId) {
                return Promise.resolve()
            }
            return backendApi
            .getTicketPrivateChat(store.state.login.user.token, ticketId, privateChatId)
            .then(payload => {
                context.commit('UPDATE_PRIVATE_CHAT', {privateChatId, payload})
            })
        },
        updateDocuments(context) {
            let ticketId = context.state.currentTicket.id
            if (!ticketId) {
                return Promise.resolve()
            }
            return backendApi
            .getTicket(context.rootState.login.user.token, ticketId)
            .then((data) => {
                context.commit('UPDATE_DOCUMENTS', data.documents)
            })
        },
        updateDocumentsTicker(context) {
            context.dispatch('updateDocuments')
            if (!context.state.updateDocumentsTicker && !!context.state.currentTicket.id) {
                context.state.updateDocumentsTicker = setInterval(
                () => context.dispatch('updateDocuments'),
                    context.state.intervalDocumentsTicker
                )
            }
        },
        clearDocumentsTicker(context) {
            if (context.state.updateDocumentsTicker) {
                clearInterval(context.state.updateDocumentsTicker)
                context.state.updateDocumentsTicker = null
            }
        },
        reset(context) {
            context.state.currentTicket = Ticket.props.ticket.default()
        }
    },
    getters: {
    },
}
