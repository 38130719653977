<template>
  <div class="card">
    <div class="card-body">
      <h6 class="card-title fw-bold">Contact : {{ contact.fullName }}</h6>
      <ul class="list-group mt-3" v-if="contact.emails.length > 0">
        <li class="list-group-item-customed list-group-item-blue">Emails</li>
        <li
            v-for="email in contact.emails"
            :key="'email-' + email.id"
            class="list-group-item"
        >
          {{ email.value }}
        </li>
      </ul>
      <ul class="list-group mt-3" v-if="contact.phoneNumbers.length > 0">
        <li class="list-group-item-customed list-group-item-blue">Numéros de téléphone</li>
        <li
            v-for="phoneNumber in contact.phoneNumbers"
            :key="'phoneNumber-' + phoneNumber.id"
            class="list-group-item"
        >
          {{ phoneNumber.value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactDisplay",
  props: {
    contact: {
      required: false,
      default: () => ({
        createdAt: null,
        updatedAt: null,
        fullName: null,
        emails: [],
        phoneNumbers: [],
      })
    }
  }
}
</script>

<style scoped>

</style>
