<template>
  <SimpleAccordion>
    <template v-slot:accordionTitle>
      Contacts pour cette adresse
    </template>
    <template v-slot:accordionContent>
      <ContactDisplay
        v-for="contact in contacts"
        :key="'contact-' + contact.id"
        :contact="contact"
        class="m-3"
      />
    </template>
  </SimpleAccordion>
</template>

<script>
import SimpleAccordion from "@/components/SimpleAccordion";
import ContactDisplay from "@/components/ContactDisplay";
export default {
  name: "ContactAccordion",
  components: {ContactDisplay, SimpleAccordion},
  props: {
    contacts: {
      required: false,
      type: Array,
      default: () => [],
    }
  }
}
</script>

<style scoped>

</style>
