<template>
  <vk-modal center :show.sync="user.token === null" class="vk-modal-background">
    <Transition name="slide-fade" mode="out-in">
      <div v-if="!$store.state.resetPassword.isResetPassword">
        <div class="uk-margin">
          <div class="uk-inline align-content-center">
            <img alt="Workr" src="./../assets/workr-logo.png">
          </div>
        </div>
        <form autocomplete="off">
          <div class="uk-margin main-place">
            <input id="username" name="username" class="uk-input uk-form-width-large uk-width uk-border-rounded" type="text" v-model="username" placeholder=" ">
            <span class="place">Nom d'utilisateur</span>
          </div>
          <div class="uk-margin is-expanded main-place">
            <input v-if="showPassword" id="password" name="password" class="uk-input uk-form-width-large uk-width uk-border-rounded custom-border-input" type="text"
                   v-model="password" placeholder=" "/>
            <input v-else id="password" name="password" class="uk-input uk-form-width-large uk-width uk-border-rounded custom-border-input" type="password"
                   v-model="password" placeholder=" "/>
            <span class="place">Mot de passe</span>
            <span class="btn btn-primary custom-border-btn-eye" @click="toggleShow">
                  <font-awesome-icon v-if="showPassword" icon="fa fa-eye-slash"/>
                  <font-awesome-icon v-else icon="far fa-eye"/>
                </span>
          </div>
          <div class="uk-margin">
            <div class="uk-inline" style="display: none">
              Je n'ai pas encore de compte. <a href="#">Ouvrir</a>
            </div>
            <div class="uk-inline uk-align-right">
              <a href="#" @click="toggleResetPassword()">Mot de passe oublié?</a>
            </div>
          </div>
          <div class="uk-margin">
            <button type="button" class="uk-button uk-button-primary uk-button-large uk-width-1-1@xl uk-border-rounded" @click="login({ username, password })"
                    :disabled="$store.state.login.loginIsInProgress">
              <span class="uk-text-large">Se connecter</span>
              <div class="ms-2" uk-spinner="ratio:0.5" v-if="$store.state.login.loginIsInProgress"></div>
            </button>
          </div>
        </form>
      </div>

      <LoginModalResetPassword v-else/>
    </Transition>
  </vk-modal>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import LoginModalResetPassword from './LoginModalResetPassword.vue'

export default {
  name: "LoginModal",
  components: {
    LoginModalResetPassword
  },
  data: () => {
    return {
      username: '',
      password: '',
      showPassword: false,
    }
  },
  computed: {
    ...mapState('login', ['user']),
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  methods: {
    ...mapActions('login', ['login']),
    ...mapActions('resetPassword', ['toggleResetPassword']),
    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  overflow: hidden;
}

.is-expanded {
  display: flex;
}

.custom-border-btn-eye {
  position: inherit;
  border-radius: 0 5px 5px 0 !important
}

.custom-border-input {
  border-radius: 5px 0 0 5px !important;
}

.align-content-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15rem;
}

.main-place {
  outline: none;
  position: relative;
  background: #FFF;
}

.main-place input {
  outline: none;
  background: #FFF;
}

.main-place .place {
  position: absolute;
  color: #AAA;
  transition: 0.2s ease all;
  top: 10px;
  left: 10px;
  padding: 0 5px 0 5px;
  pointer-events: none;
}

input:focus + .place, input:not(:placeholder-shown) + .place {
  top: -10px;
  font-size: 14px;
  color: rgb(128, 128, 128);
  background: linear-gradient(to bottom, #FFFFFF 50%, transparent);
  border-radius: 5px;
}

a:link {
  text-decoration: none;
}

.vk-modal-background {
  background: white;
}
</style>
