<template>
  <div class="progress">
    <div
        class="progress-bar bg-info"
        role="progressbar"
        :aria-valuenow="progress"
        :style="{width: progress + '%'}"
        aria-valuemin="0"
        aria-valuemax="100"
    >
      {{ progress }}%
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress: {
      required: false,
      default: () => 0,
    }
  },
}
</script>

<style scoped>

</style>
