<template>
<span class="badge uk-label uk-border-rounded m-2" :class="defineStatusClass()">
          {{ sta.value }}
          <font-awesome-icon v-if="!sta.isPublic" icon="fa-solid fa-eye-slash" class="ms-2"/>
          <div class="btn-group ms-2" v-if="removeMethod">
            <button
                type="button"
                class="btn btn-sm btn-outline-info dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                :class="defineStatusClass"
            >
              X
            </button>
            <ul class="dropdown-menu bg-transparent border-0">
              <li>
                <button type="button" class="btn btn-warning"
                        @click="removeMethod()">Confirmer le retrait du statut</button>
              </li>
            </ul>
          </div>
        </span>
</template>

<script>

export default {
    name: "StatusElement",
    props: {
        sta: {
            type: Object,
            required: true,
        },
        isStatusPresentInCurrentUserStatusList: {
            type: Boolean,
            required: true,
        },
        removeMethod: {
            type: Function,
            required: false,
            default: undefined,
        },
    },
    methods: {
        defineStatusClass() {
            console.log(this.sta)
            return {
                'public-status-for-current-user': this.sta.isPublic && this.isStatusPresentInCurrentUserStatusList,
                'private-status-for-current-user': !this.sta.isPublic && this.isStatusPresentInCurrentUserStatusList,
                'public-status-for-another-user': this.sta.isPublic && !this.isStatusPresentInCurrentUserStatusList,
            }
        }
    },
}
</script>

<style scoped>
.public-status-for-current-user {
    background-color: #2cb4db !important;
    color: rgb(33, 37, 41) !important;
}

.private-status-for-current-user {
    background-color: #172B4D !important;
    color: white !important;
}

.public-status-for-another-user {
    background-color: #41b883 !important;
    color: rgb(33, 37, 41) !important;
}
</style>
