<template>
  <div>
    <div class="m-1 mb-3">
      Ordre {{ missionOrder.id }} -
      Créé le : {{ missionOrder.createdAt | humanReadableDate }} -
      Adresse : {{ missionOrder.ticket.property.address.formattedAddress }} -
      Propriété : {{ missionOrder.ticket.property.name }}
      <span v-if="missionOrder.assignedToSuperManager">- <span class="badge bg-success">Ordre délégué</span></span>
    </div>
    <ContactAccordion :contacts="missionOrder.ticket.property.contacts" class="mb-3" />
    <ObjectAccordion :object="missionOrder.ticket.mainPurpose" :title="`Objet original du ticket ${missionOrder.ticket.id}`" class="mb-3" />
    <StatusAccordion class="mb-3" :status="missionOrder.status" :add-status-method="addStatusMethod" :remove-status-method="removeStatusMethod" />
    <AttachWorkerUserAccordion v-if="$store.state.login.user.userData.accountType === 'workerAccount' && $store.state.login.user.userData.roles.includes('admin')" class="mb-3" :company-user-list="$store.state.users.list"
      :attached-users="missionOrder.assignedWorkerUsers" :update-user-selection-method="updateUserSelection" />
    <QuotationsList v-if="!['suez-remote'].includes($env.VUE_APP_MODE)" class="mb-3" :quotations="missionOrder.quotations" />
    <InvoicesList v-if="!['suez-remote'].includes($env.VUE_APP_MODE)" class="mb-3" :invoices="missionOrder.invoices" />
    <DocumentsViewAndLoad class="mb-3" :documents="missionOrder.documents" :add-documents-method="addDocuments" :save-edited-image-method="saveEditedImage" :edit-document-name-func="editDocumentName"
      :delete-document-func="deleteDocument" />
    <ChatAccordion class="mb-3" :chat="missionOrder.mainChat" accordion-title="Chat public (messages visibles par toute personne rattachée à l'ordre de mission)" @message:new="recordNewMessageToMainChat"
      @chat:accordion:open="updateMainChat" />
    <ClosedActionAccordion v-if="this.$store.state.login.user.userData.accountType === 'agencyAccount'" class="mb-3" :closed-method="closeMissionOrder" :unclosed-method="uncloseMissionOrder"
      :remote-closed-at="missionOrder.closedAt" :remote-closed-reason="missionOrder.closedReason" />
  </div>
</template>

<script>
import QuotationsList from "@/components/QuotationsList";
import InvoicesList from "@/components/InvoicesList";
import StatusAccordion from "@/components/StatusAccordion";
import ChatAccordion from "@/components/ChatAccordion";
import { mapActions, mapMutations } from "vuex";
import backendApi from "@/backend/api";
import store from "@/store";
import DocumentsViewAndLoad from "@/components/DocumentsViewAndLoad";
import AttachWorkerUserAccordion from "@/components/AttachWorkerUserAccordion";
import ClosedActionAccordion from "@/components/ClosedActionAccordion";
import ObjectAccordion from "@/components/ObjectAccordion";
import ContactAccordion from "@/components/ContactAccordion";
export default {
  name: "MissionOrder",
  components: {
    ContactAccordion,
    ObjectAccordion,
    ClosedActionAccordion,
    AttachWorkerUserAccordion,
    DocumentsViewAndLoad,
    ChatAccordion, StatusAccordion, InvoicesList, QuotationsList
  },
  props: {
    missionOrder: {
      required: false,
      default: () => ({
        id: null,
        ticket: {
          id: null,
          property: {
            id: null,
            name: '',
            address: {
              id: null,
              formattedAddress: ''
            }
          }
        },
        mainChat: {
          id: null,
        },
        privateChats: [],
        status: [],
        quotations: [],
        notes: [],
        invoices: [],
        documents: [],
        assignedToSuperManager: null,
        assignedToWorker: null,
        closedAt: null,
        closedReason: null,
        createdAt: '',
        updatedAt: '',
      }),
    },
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    ...mapActions('missionOrders', ['updateMainChat', 'updatePrivateChat']),
    ...mapMutations('missionOrders', ['UPDATE_DOCUMENTS']),
    recordNewMessageToMainChat(message, newMessageResult, siteUrl) {
      backendApi
        .addMessageToMissionOrderMainChat(store.state.login.user.token, this.missionOrder.id, message, siteUrl)
        .then(() => {
          this.updateMainChat()
          this.addNotification({
            message: 'Message envoyé',
            status: 'success',
          })
          newMessageResult(true)
        })
        .catch(error => {
          newMessageResult(false)
          backendApi.notificationOnError(error).catch(() => { })
        })
    },
    recordNewMessageToPrivateChat(privateChatId, message, newMessageResult) {
      backendApi
        .addMessageToMissionOrderPrivateChat(store.state.login.user.token, this.missionOrder.id, privateChatId, message)
        .then(() => {
          this.updatePrivateChat(privateChatId)
          this.addNotification({
            message: 'Message envoyé',
            status: 'success',
          })
          newMessageResult(true)
        })
        .catch(error => {
          newMessageResult(false)
          backendApi.notificationOnError(error).catch(() => { })
        })
    },
    addDocuments(filesUploadedToAdd) {
      return backendApi
        .addDocumentsToMissionOrder(store.state.login.user.token, this.missionOrder.id, filesUploadedToAdd)
        .then(data => {
          this.UPDATE_DOCUMENTS(data.documents)
        })
        .catch(backendApi.notificationOnError)
    },
    saveEditedImage(file) {
      return backendApi
        .uploadFile(store.state.login.user.token, file)
        .then(data => backendApi.addDocumentsToMissionOrder(store.state.login.user.token, this.missionOrder.id, [data]))
        .then(data => {
          this.UPDATE_DOCUMENTS(data.documents)
        })
        .catch(backendApi.notificationOnError)
    },
    addStatusMethod(statusId) {
      return backendApi
        .addStatusToMissionOrder(store.state.login.user.token, this.missionOrder.id, statusId)
        .then(data => { this.missionOrder.status = data.status })
    },
    removeStatusMethod(statusId) {
      return backendApi
        .removeStatusToMissionOrder(store.state.login.user.token, this.missionOrder.id, statusId)
        .then(data => { this.missionOrder.status = data.status })
    },
    updateUserSelection(usersSelected) {
      return backendApi
        .updateMissionOrderAssignedWorkerUsers(store.state.login.user.token, this.missionOrder.id, usersSelected)
        .then(data => {
          this.addNotification({ status: 'success', message: 'Utilisateurs rattachés modifié' })
          return this.missionOrder.assignedWorkerUsers = data.assignedWorkerUsers
        })
        .catch(backendApi.notificationOnError)
    },
    closeMissionOrder(reason) {
      return backendApi
        .closeMissionOrder(store.state.login.user.token, this.missionOrder.id, reason)
        .then(data => {
          this.missionOrder = data
          this.addNotification({ status: 'success', message: 'Clôture de l\'ordre de mission effectué' })
          this.$router.push({ name: 'tickets' })
        })
        .catch(backendApi.notificationOnError)
    },
    uncloseMissionOrder() {
      return backendApi
        .uncloseMissionOrder(store.state.login.user.token, this.missionOrder.id)
        .then(data => {
          this.missionOrder = data
          this.addNotification({ status: 'success', message: 'Dé-clôture de l\'ordre de mission effectué' })
        })
        .catch(backendApi.notificationOnError)
    },
    editDocumentName(document, newName) {
      return backendApi
        .renameMissionOrderDocument(store.state.login.user.token, this.missionOrder.id, document.id, newName)
        .then(data => {
          this.UPDATE_DOCUMENTS(data.documents)
        })
        .catch(backendApi.notificationOnError)
    },
    deleteDocument(document) {
      return backendApi
        .deleteMissionOrderDocument(store.state.login.user.token, this.missionOrder.id, document.id)
        .then(data => {
          this.UPDATE_DOCUMENTS(data.documents)
        })
        .catch(backendApi.notificationOnError)
    }
  },
}
</script>

<style scoped></style>
