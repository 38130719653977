<template>
  <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
    <vk-nav-item-header title="Tickets et ordres" style="color: #999999"/>
    <li class="uk-parent">
      <a href="#" style="color: white">
        <vk-icon icon="location" class="uk-margin-small-right"/>
        Tickets</a>
      <ul class="uk-nav-sub">
        <vk-nav-item @click="$router.push({ name: 'tickets' }); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'tickets'}" title="Liste"/>
        <vk-nav-item @click="$router.push({ name: 'ticket_new' }); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'ticket_new'}" title="Créer"/>
      </ul>
    </li>
    <li>
      <a href="#" @click.prevent="$router.push({ name: 'mission_orders' }); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'mission_orders'}" style="color: white">
        <vk-icon icon="nut" class="uk-margin-small-right"/>
        Ordres de mission</a>
    </li>
    <vk-nav-item-divider/>
    <vk-nav-item-header title="Gestion interne" style="color: #999999"/>
    <li class="uk-parent">
      <a href="#" style="color: white">
        <vk-icon icon="location" class="uk-margin-small-right"/>
        Propriétés</a>
      <ul class="uk-nav-sub">
        <vk-nav-item @click="$router.push({ name: 'properties' }); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'properties'}" title="Lister / Modifier"/>
        <vk-nav-item @click="$router.push({ name: 'property_new' }); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'property_new'}" title="Créer"/>
      </ul>
    </li>
    <li class="uk-parent">
      <a href="#" style="color: white">
        <font-awesome-icon icon="fa-solid fa-screwdriver-wrench" class="uk-margin-small-right" />
        Sous-traitants ou prestataires
      </a>
      <ul class="uk-nav-sub">
        <vk-nav-item @click="$router.push({ name: 'workers_list' }); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'workers_list'}" title="Lister / Retirer"/>
        <vk-nav-item @click="$router.push({ name: 'worker_add' }); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'worker_add'}" title="Ajouter"/>
      </ul>
    </li>

    <ItemManageStatus v-on="$listeners"/>
    <ItemManageUsers v-on="$listeners" v-if="$store.state.login.user.userData.roles.includes('admin')"/>
    <li class="uk-parent" style="display: none">
      <a href="#" style="color: white">
        <vk-icon icon="play-circle" class="uk-margin-small-right"/>
        Utiliser l'application</a>
      <ul class="uk-nav-sub">
        <vk-nav-item @click="$router.push({ name: 'video-formations' }); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'video-formations'}" title="Lister"/>
        <!-- <vk-nav-item @click="$router.push({name: 'property_new'}); $emit('nav:close')" :class="{ 'active-on-click': $route.name === 'property_new'}" title="Créer"/> -->
      </ul>
    </li>
  </ul>
</template>

<script>
import ItemManageStatus from "@/components/menuItems/ItemManageStatus";
import ItemManageUsers from "@/components/menuItems/ItemManageUsers";

export default {
  name: "ItemsAgencyAccount",
  components: {ItemManageUsers, ItemManageStatus}
}

</script>

<style scoped>
.uk-nav-default .uk-nav-sub a {
  color: #999 !important;
}
</style>
