<template>
  <SimpleAccordion>
    <template v-slot:accordionTitle>Factures</template>
    <template v-slot:accordionContent>
      <vue-good-table
          styleClass="vgt-table striped bordered condensed"
          :columns="columns"
          :rows="invoices"
      >
        <template v-slot:table-row="props">
          <div v-if="props.column.field === 'actions'" class="d-flex flex-column gap-2">
            <button class="btn btn-primary" @click="downloadDocument(props.row.document.randomIdentifier, props.row.document.originalName)">
              <font-awesome-icon icon="fa-solid fa-file-arrow-down" style="font-size: 1rem"/>
            </button>
          </div>
          <div v-else-if="props.column.label === 'Objet'">
            <div class="m-2">
              {{ props.formattedRow[props.column.field] }}
            </div>
            <SimpleAccordion
                v-if="hiddenFields.length > 0"
            >
              <template v-slot:accordionTitle>Plus d'infos</template>
              <template v-slot:accordionContent>
                <div class="p-2">
                  <div v-if="hiddenFields.includes('amountWoTax')" class="mb-3">
                    <p>Montant HT : {{ props.formattedRow['amountWoTax'] }}<br>
                      Montant TTC : {{ props.formattedRow['amountWithTax'] }}</p>
                  </div>
                  <div v-if="hiddenFields.includes('document.ownedBy')" class="mb-3">
                    <p>Par : {{ props.formattedRow['document.ownedBy'] }}</p>
                  </div>
                  <div v-if="hiddenFields.includes('createdAt')" class="mb-3">
                    <p>Le : {{ props.formattedRow['createdAt'] }}</p>
                  </div>
                </div>
              </template>
            </SimpleAccordion>
          </div>
          <div v-else>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </template>
      </vue-good-table>
    </template>
  </SimpleAccordion>
</template>

<script>
import backendApi from "@/backend/api";
import store from "@/store";
import SimpleAccordion from "@/components/SimpleAccordion";
import Vue from "vue";

export default {
  name: "InvoicesList",
  components: {SimpleAccordion},
  props: {
    invoices: {
      required: false,
      default: () => [],
    }
  },
  computed: {
    columns: function () {
      return [
        {
          label: 'Objet',
          field: (rowObj) => {
            return rowObj
          },
          hidden: this.shouldBeHidden('xs'),
          formatFn: (row) => {
            return row.mainPurpose === 'undefined' ? row.number : row.mainPurpose
          }
        },
        {
          label: 'Montant HT',
          field: 'amountWoTax',
          hidden: this.shouldBeHidden('sm'),
          formatFn: Vue.filter('amountFormat'),
        },
        {
          label: 'Montant TTC',
          field: 'amountWithTax',
          hidden: this.shouldBeHidden('sm'),
          formatFn: Vue.filter('amountFormat'),
        },
        {
          label: 'Envoyé par',
          field: 'document.ownedBy',
          hidden: this.shouldBeHidden('xl'),
          formatFn: this.ownedByDisplay,
        },
        {
          label: 'Le',
          field: 'createdAt',
          hidden: this.shouldBeHidden('xl'),
          formatFn: Vue.filter('humanReadableDate'),
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '1%',
        },
      ]
    },
    hiddenFields: function () {
      let hiddenFields = []
      this.columns.forEach(column => {
        if (column.hidden) {
          hiddenFields.push(column.field)
        }
      })

      return hiddenFields
    },
  },
  methods: {
    shouldBeHidden: function (at) {
      const bp = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'big', 'giga',]
      if (!bp.includes(at)) {
        return false;
      }

      return !(bp.indexOf(at) <= bp.indexOf(this.$mq))
    },
    ownedByDisplay(ownedBy) {
      if (!ownedBy) {
        return 'legacy - unknown'
      }

      return `${ownedBy.username} - ${ownedBy.userInfos.lastName} ${ownedBy.userInfos.firstName}`
    },
    downloadDocument(identifier, originalName) {
      backendApi
          .downloadDocument(store.state.login.user.token, identifier, originalName)
    }
  }
}
</script>

<style scoped>
</style>
