import backendApi from '@/backend/api'

export default {
    namespaced: true,
    state: () => ({
        notifications: [],
        messageReadHistoryLastCreatedAt: null,
        updateNotificationTicker: null,
        updateNotificationInterval: 5000,
    }),
    mutations: {
        SET_NOTIFICATIONS(store, payload) {
            store.notifications = payload
            console.log(store.notifications)
        },
        ADD_NOTIFICATIONS(store, payload) {
            store.notifications = store.notifications.concat(payload)
            console.log(store.notifications)
        },
        SET_MESSAGE_READ_HISTORY_LAST_CREATED_AT(store, payload) {
            store.messageReadHistoryLastCreatedAt = payload
        },
        CLEAR_NOTIFICATIONS(store) {
            store.notifications = []
            store.messageReadHistoryLastCreatedAt = null
        },
    },
    actions: {
        setNotifications(context) {
            context.commit('CLEAR_NOTIFICATIONS')
            return backendApi.getMessageReadHistory(context.rootState.login.user.token)
            .then(data => {
                let notifications = []
                data.forEach((messageReadHistory) => {
                    notifications.push({
                        type: 'messageReadHistory',
                        messageReadHistory,
                    })
                })
                if (!notifications.length) {
                    return
                }
                context.commit(
                    'SET_MESSAGE_READ_HISTORY_LAST_CREATED_AT',
                    notifications[notifications.length - 1].messageReadHistory.createdAt
                )
                context.commit('ADD_NOTIFICATIONS', notifications)
            })
            .catch(() => {})
        },
        updateNotifications(context) {
            if (context.state.messageReadHistoryLastCreatedAt) {
                return backendApi
                .getMessageReadHistoryFrom(context.rootState.login.user.token, context.state.messageReadHistoryLastCreatedAt)
                .then(data => {
                    let notifications = []
                    data.forEach((messageReadHistory) => {
                        notifications.push({
                            type: 'messageReadHistory',
                            messageReadHistory,
                        })
                        context.dispatch('localNotifications/addNotification', {
                            id: new Date().getTime(),
                            title: 'Nouveau message',
                            body: messageReadHistory.message.value,
                            largeBody: messageReadHistory.message.value,
                        }, {root: true})
                    })
                    if (!notifications.length) {
                        return
                    }
                    context.commit(
                        'SET_MESSAGE_READ_HISTORY_LAST_CREATED_AT',
                        notifications[notifications.length - 1].messageReadHistory.createdAt
                    )
                    context.commit('ADD_NOTIFICATIONS', notifications)
                })
            }
            return context.dispatch('setNotifications')
        },
        deleteNotification(context, notificationId) {
            return backendApi
            .deleteMessageReadHistory(context.rootState.login.user.token, notificationId)
            .then(data => {
                let notifications = []
                data.forEach((messageReadHistory) => {
                    notifications.push({
                        type: 'messageReadHistory',
                        messageReadHistory,
                    })
                })
                let lastCreatedAt = null
                if (notifications.length) {
                    lastCreatedAt = notifications[notifications.length - 1].messageReadHistory.createdAt
                }
                context.commit(
                    'SET_MESSAGE_READ_HISTORY_LAST_CREATED_AT',
                    lastCreatedAt
                )
                context.commit('SET_NOTIFICATIONS', notifications)
            })
        },
        updateNotificationTicker(context) {
            context.dispatch('updateNotifications')
            if (!context.state.updateNotificationTicker) {
                context.state.updateNotificationTicker = setInterval(
                () => context.dispatch('updateNotifications'),
                context.state.updateNotificationInterval
                )
            }
        },
        clearNotificationTicker(context) {
            if (context.state.updateNotificationTicker) {
                clearInterval(context.state.updateNotificationTicker)
                context.state.updateNotificationTicker = null
            }
        },
    },
    getters: {
    },
}
