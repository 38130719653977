<template>
  <form id="form">
    <FileUpload choix-file-text="Selectionnez le document" :is-multiple="false" :files-uploaded.sync="documents" />
    <div class="uk-margin">
      <label class="uk-form-label">Objectif principal</label>
      <div class="uk-form-controls">
        <input class="uk-input" type="text" required v-model="form.mainPurpose">
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Montant TTC</label>
      <div class="uk-form-controls">
        <input step=".01" class="uk-input" type="number" required v-model="form.amountWithTax">
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Montant HT</label>
      <div class="uk-form-controls">
        <input step=".01" class="uk-input" type="number" required v-model="form.amountWoTax">
      </div>
    </div>
    <label class="d-block" v-if="true === false"> <!-- temporary disable -->
      <input class="form-check-input" type="checkbox" v-model="form.requireTaxReduceForm">
      Formulaire de réduction d'impôt
    </label>
    <button ref="submit" hidden></button>
  </form>
</template>

<script>
import FileUpload from "@/components/FileUpload"
import { mapActions } from "vuex"

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      documents: [],
      form: {}
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    submit() {
      this.$refs.submit.click()
    },
    initInput() {
      this.documents = []
      this.form = {
        mainPurpose: '',
        amountWithTax: 0,
        amountWoTax: 0,
        isActive: true,
        requireTaxReduceForm: false,
        dataUrl: window.location.protocol + '//' + window.location.host
      }
    }
  },
  mounted() {
    const that = this
    this.initInput()
    const form = document.querySelector('#form')
    form.onsubmit = function () {
      if (that.documents[0]) {
        that.$emit('add-quotation', { ...that.form, document: that.documents[0] })
      } else {
        that.addNotification({ status: 'warning', message: 'Veuillez sélectionner le document' })
      }
      return false;
    };
  }
}
</script>

<style></style>